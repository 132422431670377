import Sponsorship1 from '@/assets/img/corporate-and-organization/sponsorship_img1@2x.jpg';
import Sponsorship1M from '@/assets/img/corporate-and-organization/sponsorship_img1M@2x.jpg';
import Sponsorship2 from '@/assets/img/corporate-and-organization/sponsorship_img2@2x.jpg';
import Sponsorship2M from '@/assets/img/corporate-and-organization/sponsorship_img2M@2x.jpg';
import Sponsorship3 from '@/assets/img/corporate-and-organization/sponsorship_img3@2x.jpg';
import Sponsorship3M from '@/assets/img/corporate-and-organization/sponsorship_img3M@2x.jpg';
import SponsorMap from '@/assets/img/corporate-and-organization/sponsorship_map@2x.png';
import SponsorMapM from '@/assets/img/corporate-and-organization/sponsorship_mapM@2x.png';
import ContactUs from '@/components/ContactUs';
import Container from '@/components/Container';
import Image from '@/components/Image';
import PageComponent from '@/components/PageComponent';
import Section from '@/components/Section';
import SectionHeader from '@/components/SectionHeader';
import { Tit } from '@/components/Titles';
import { breakpoint } from '@/helpers/BreakpointHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { PageProps } from 'gatsby';
import React, { FC } from 'react';
import styled from 'styled-components';

// tmp / corporate - and - organization;

const SponsorData = [
  {
    id: 1,
    pcImg: Sponsorship1,
    mobileImg: Sponsorship1M,
    title: `유니세프 경남후원회`,
    dec: `2020년 10월 26일, 유니세프 경남후원회(회장 박윤규)는 코로나19로 어려움을 겪고 있는 창원 지역 다문화가정 어린이를 응원하기 위해 유니세프 희망키트 1,000세트를 전달했습니다.`,
  },
  {
    id: 2,
    pcImg: Sponsorship2,
    mobileImg: Sponsorship2M,
    title: `유니세프 함평군후원회`,
    dec: `2019년 11월 29일, 함평 나비축제 및 국화축제 수익금 600만 원을 유니세프한국위원회에 전달했습니다. 함평군과 유니세프 함평군후원회(회장 이재갑)는 지역 대표축제인 나비축제와 국향대전에서 후원금 모금활동을 펼치고 있습니다.`,
  },
  {
    id: 3,
    pcImg: Sponsorship3,
    mobileImg: Sponsorship3M,
    title: `유니세프 금정구후원회`,
    dec: `2018년 8월 16일, 유니세프 금정구후원회(회장 이상규)는 지난 14일 유니세프 부산사무소를 방문해 라오스 수해 긴급구호금 500만 원을 전달했습니다.`,
  },
];

const contactData = [
  {
    id: 1,
    tit: `문의`,
    costumer: `부산사무소`,
    tel: `051-501-0087`,
    email: `busan@unicef.or.kr`,
  },
];

const SectionTop = styled(Section)`
  ${breakpoint(`mobile`)} {
    img {
      width: 100%;
    }
  }
`;

const SponsorCard = styled.div`
  display: flex;
  padding-bottom: 100px;
  background: #e2f0f6;
  position: relative;
  margin-bottom: 96px;

  &:last-child {
    margin-bottom: 0 !important;
  }

  &::before,
  &::after {
    content: '';
    display: block;
    width: 240px;
    position: absolute;
  }

  &::before {
    height: 100%;
    background: #fff;
    left: 0;
    top: 0;
  }

  &::after {
    height: 240px;
    background: #b2def1;
    left: 240px;
    bottom: 0;
    border-radius: 0 240px 0 0;
  }

  .col-img,
  .col-dec {
    position: relative;
    z-index: 1;
  }
  .col-img {
    width: 48%;
  }

  .col-dec {
    width: 52%;
    padding-left: 40px;
    padding-top: 56px;
    padding-right: 64px;

    p {
      margin-top: 16px;
    }
  }

  ${breakpoint(1160)} {
    padding-bottom: 8.62vw;
    margin-bottom: 72px;

    &::before,
    &::after {
      width: 20.69vw;
    }

    &::after {
      height: 20.69vw;
      left: 20.69vw;
      border-radius: 0 20.69vw 0 0;
    }

    .col-dec {
      padding-left: 3.45vw;
      padding-top: 4.83vw;
      padding-right: 5.52vw;
    }
  }
  ${breakpoint(`mobile`)} {
    margin: 0 -20px;
    padding-bottom: 112px;
    flex-wrap: wrap;

    &::before {
      display: none;
    }

    &::after {
      width: 167px;
      height: 167px;
      left: 0;
      border-radius: 0 167px 0 0;
    }

    .col-dec {
      padding: 32px 20px;
      padding-bottom: 0;
    }

    .col-img,
    .col-dec {
      width: 100%;
      img {
        width: 100%;
      }
    }
  }
`;

const Sponsorship: FC<PageProps> = ({ location }) => (
  <LayoutWithTitle
    location={location}
    title="후원회"
    description="for every child, partnerships"
  >
    <SectionTop className="by-sub-main-layout">
      <Container>
        <SectionHeader className="with-desc">
          <h2>
            <Tit size="s1-5" color="sky" weight="normal">
              <PageComponent id="title1">
                같은 지역에 거주하는 <br />
                사람들과 함께해보세요
              </PageComponent>
            </Tit>
          </h2>
          <p className="header-dec">
            <PageComponent id="content1">
              같은 지역에서 유니세프 모금 및 아동권리 옹호에 동참해 전 세계
              어린이들의 삶을 변화시킬 수 있습니다.
            </PageComponent>
          </p>
        </SectionHeader>
        <Image
          pcSrc={SponsorMap}
          mobileSrc={SponsorMapM}
          css={`
            width: auto;
            margin: 0 auto;
            display: block;
            max-width: 100%;
          `}
        />
      </Container>
    </SectionTop>

    <Section className="by-sub-main-layout">
      <Container>
        <div className="sponsorship-container">
          {SponsorData.map((row) => (
            <SponsorCard>
              <div className="col-img">
                <Image pcSrc={row.pcImg} mobileSrc={row.mobileImg} />
              </div>
              <div className="col-dec">
                <Tit size="s3" color="sky">
                  {row.title}
                </Tit>
                <p>{row.dec}</p>
              </div>
            </SponsorCard>
          ))}
        </div>
      </Container>
    </Section>

    <Section className="by-sub-main-layout">
      <Container>
        <ContactUs itemData={contactData} />
      </Container>
    </Section>
  </LayoutWithTitle>
);

export default Sponsorship;
